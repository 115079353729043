export const queryKeys = {
  user: 'user',
  social: 'social',
  home: 'home',
  banners: 'banners',
  teachers: 'teachers',
  teacher: 'teacher',
  lectures: 'lectures',
  lecture: 'lecture',
  classes: 'classes',
  classes_detail: 'classes_detail',
  classes_chapter: 'classes_chapter',
  classes_teacher: 'classes_teacher',
  order_detail: 'order_detail',
  order_bank: 'order_bank',
  categories: 'categories',
  categories_detail: 'categories_detail',
  groups: 'groups',
  group: 'group',
  areas: 'areas',
  area: 'area',
  users: 'users',
  users_teacher: 'users_teacher',
  users_classes: 'users_classes',
  users_schedules: 'users_schedules',
  users_orders: 'users_orders',
};
