import { createBrowserRouter } from 'react-router-dom';
import homeRoutes from './homeRoutes';
import authRoutes from './authRoutes';
import classesRoutes from './classesRoutes';
import passwordRoutes from './passwordRoutes';
import teachersRoutes from './teachersRoutes';
import usersRoutes from './usersRoutes';
import mineRoutes from './mineRoutes';

export default createBrowserRouter([
  homeRoutes,
  authRoutes,
  passwordRoutes,
  usersRoutes,
  // classesRoutes,
  teachersRoutes,
  mineRoutes,
]);
