import { PropsWithChildren } from 'react';
import { getStoredUser } from 'react-query/storage';
import { useSetRecoilState } from 'recoil';
import { userState } from 'states/userState';

function AuthProvider({ children }: PropsWithChildren) {
  const setUser = useSetRecoilState(userState);
  const user = getStoredUser();

  if (user) setUser(user);
  else setUser(null);

  return <>{children}</>;
}

export default AuthProvider;
