import { deleteAuth } from 'api/auth';
import AnimateLink from 'components/link/AnimateLink';
import useViewTransition from 'hooks/useViewTransition';
import { useQueryClient } from 'react-query';
import { queryKeys } from 'react-query/constants';
import { clearStoredUser } from 'react-query/storage';
import { useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { userState } from 'states/userState';
import styled from 'styled-components';
import colors from 'styles/theme/colors';

const SIGN_UP = '/auth/sign-up?type=basic';
const SIGN_IN = '/auth/sign-in';
const MY_PAGE = '/users';

const AuthNav = () => {
  const { pathname, search } = useLocation();
  const { onClickViewTransition } = useViewTransition({ link: '/' });
  const [user, setUser] = useRecoilState(userState);
  const queryClient = useQueryClient();

  const handleLogout = async () => {
    try {
      await deleteAuth();
      setUser(null);
      clearStoredUser();

      // remove query
      queryClient.removeQueries(queryKeys.user);
      queryClient.removeQueries(queryKeys.users);

      onClickViewTransition();
    } catch (e) {
      // TODO: Error handling
      throw e;
    }
  };

  return (
    <>
      {user ? (
        <StyledAuthNav>
          <AnimateLink
            to={MY_PAGE}
            style={{ color: `${pathname === MY_PAGE ? colors.keyColor.keyColor01 : colors.system.darkGray}` }}
          >
            마이페이지
          </AnimateLink>
          <span onClick={handleLogout}>로그아웃</span>
        </StyledAuthNav>
      ) : (
        <StyledAuthNav>
          <AnimateLink
            to={SIGN_UP}
            style={{
              color: `${pathname === SIGN_UP?.split('?')[0] ? colors.keyColor.keyColor01 : colors.system.darkGray}`,
            }}
          >
            회원가입
          </AnimateLink>
          <AnimateLink
            state={pathname + search}
            to={SIGN_IN}
            style={{ color: `${pathname === SIGN_IN ? colors.keyColor.keyColor01 : colors.system.darkGray}` }}
          >
            로그인
          </AnimateLink>
        </StyledAuthNav>
      )}
    </>
  );
};

export default AuthNav;

const StyledAuthNav = styled.nav`
  display: flex;
  align-items: center;
  gap: 24px;

  span {
    cursor: pointer;
  }
`;
