import { css } from 'styled-components';

export const portone = css`
  #imp-iframe {
    width: 100% !important;
    margin-top: 0 !important;
    margin-left: 0 !important;
    height: 100% !important;
    overflow: hidden;
  }
`;
