import { PropsWithChildren, ReactNode } from 'react';
import { FieldValues, FormProvider, UseFormProps, UseFormReturn } from 'react-hook-form';

interface FormProps<T extends FieldValues> extends UseFormProps<T> {
  form: UseFormReturn<T>;
  onSubmit: (data: T) => void;
  children: ReactNode | ReactNode[];
}

function Form<T extends FieldValues>(props: PropsWithChildren<FormProps<T>>) {
  const { form, onSubmit, children } = props;

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>{children}</form>
    </FormProvider>
  );
}

export default Form;
