import { css } from 'styled-components';
import colors from 'styles/theme/colors';

export const modal = css`
  .title {
    color: ${colors.system.black};
    text-align: center;
    font-size: 21px;
    font-weight: 500;
    line-height: 32px;
  }
  div#swal2-html-container.htmlContainer {
    color: ${colors.system.black};
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
  }
  .buttons {
    width: 100%;
    gap: 15px;
    padding: 0 10px;
    max-width: 480px;
  }
  .btn {
    border-radius: 4px;
    border: 1px solid ${colors.keyColor.keyColor01};
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.056px;
    height: 48px;
    flex: 1;
    cursor: pointer;
  }
  .btn-success {
    color: #fff;
    background-color: ${colors.keyColor.keyColor01};
  }
  .btn-cancel {
    background-color: #fff;
    color: ${colors.keyColor.keyColor01};
  }
`;
