import instance from '../index';
import { AxiosResponse } from 'axios';
import {
  AuthResponse,
  EditPasswordSchemaType,
  NaverType,
  ResetPasswordSchemaType,
  SignInSchemaType,
  SignInSocialSchemaType,
  SignUpSchemaType,
  SignUpSocialSchemaType,
} from './type';

const PATH = '/auth';

export const getSignIn = async () => {
  return await instance.get(PATH);
};

export const postSignIn = async (data: SignInSchemaType) => {
  return await instance.post(PATH, data);
};

export const postSignUp = async (data: SignUpSchemaType | SignUpSocialSchemaType) => {
  return await instance.post(`${PATH}/register`, data);
};

export const postSignInSocial = async (data: SignInSocialSchemaType) => {
  return await instance.post(PATH + '/social', data);
};

export const postNaverGetProfile = async (data: SignInSocialSchemaType): Promise<NaverType> => {
  return await instance.post(PATH + '/social/naver', data).then((data) => data.data);
};

export const getEmailCheck = async (email: string) => {
  return await instance.get(`${PATH}/email`, {
    params: {
      email,
    },
  });
};

export const postResetPassword = async (data: ResetPasswordSchemaType) => {
  return await instance.post(`${PATH}/reset-password`, data);
};

export const getAuth = async (): Promise<AxiosResponse<AuthResponse>> => {
  return await instance.get(PATH);
};

export const putAuth = async (data: EditPasswordSchemaType) => {
  return await instance.put(PATH, data);
};

export const deleteAuth = async () => {
  return await instance.delete(PATH);
};
