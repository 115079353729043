import { createGlobalStyle } from 'styled-components';
import { modal } from './modal';
import { reset } from './reset';
import { portone } from './portone';

export const GlobalStyle = createGlobalStyle`
    ${reset};
    ${modal};
    ${portone};
`;
