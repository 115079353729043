import loadable from '@loadable/component';

const BasicLayout = loadable(() => import('layouts/BasicLayout'));
const TeacherDetail = loadable(() => import('pages/teachers/TeacherDetail'));
const TeacherQuestion = loadable(() => import('pages/teachers/TeacherQuestion'));
const Teachers = loadable(() => import('pages/teachers/Teachers'));
const Error404 = loadable(() => import('layouts/Error404'));

export default {
  path: '/teachers',
  element: <BasicLayout />,
  children: [
    { index: true, element: <Teachers /> },
    { path: 'detail/:id', element: <TeacherDetail /> },
    { path: 'detail/:id/question', element: <TeacherQuestion /> },
  ],
  errorElement: <Error404 />,
};
