import React from 'react';
import ReactDOM from 'react-dom/client';
import { ReactQueryDevtools } from 'react-query/devtools';
import { RouterProvider } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import router from 'routes/routes';
import { ThemeProvider } from 'styled-components';
import { GlobalStyle } from 'styles/global/global';
import { Theme } from 'styles/theme';
import { queryClient } from './lib/queryClient';
import reportWebVitals from './reportWebVitals';

import './styles/global/font.css';

import { QueryClientProvider } from 'react-query';
import AuthProvider from 'provider/AuthProvider';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <RecoilRoot>
        <ReactQueryDevtools />
        <ThemeProvider theme={Theme}>
          <GlobalStyle />

          <AuthProvider>
            <RouterProvider router={router} />
          </AuthProvider>
        </ThemeProvider>
      </RecoilRoot>
    </QueryClientProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
