import useViewTransition from 'hooks/useViewTransition';
import { Link, LinkProps } from 'react-router-dom';

type AnchorProps = Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, keyof LinkProps>;

interface AnimateLinkProps extends AnchorProps, LinkProps {
  children: React.ReactNode;
}

function AnimateLink({ state, ...props }: AnimateLinkProps) {
  const { to } = props;
  const { onClickViewTransition: router } = useViewTransition({ state, link: to as string });

  const handleLinkClick = async (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();

    router();
  };

  return <Link {...props} onClick={handleLinkClick} />;
}

export default AnimateLink;
