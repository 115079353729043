const keyColor = {
  keyColor01: '#009891',
  keyColor80: '#00949ECC',
  keyColor60: '#00989199',
  keyColor40: '#00989166',
  keyColor20: '#00989133',
  keyColorLinear01: 'linear-gradient(90deg, #4dc0ba 0%, #1ca38b 100%)',
};

const gray = {
  gray01: '#8E8E93',
  gray02: '#AEAEB2',
  gray03: '#C7C7CC',
  gray04: '#D1D1D6',
  gray05: '#E5E5EA',
  gray06: '#F2F2F7',
  gray50: '#F5F5F8',
};

const system = {
  black: '#1B1C1E',
  darkGray: '#616161',
  gray: '#858585',
  white: '#FFFFFF',
  link: '#2352AC',
  error: '#F64444',
  pink: '#F05E81',
  tagColor: '#8F9BBA',
  orange: '#EC6E00',
  brown: '#AC2900',
  green: '#009D6C',
  yellow: '#FFC300',
  lightGray: '#979797',
};

const allColors = {
  ...keyColor,
  ...gray,
  ...system,
};

const colors = {
  keyColor,
  gray,
  system,
  allColors,
};

export default colors;
