import loadable from '@loadable/component';

const BasicLayout = loadable(() => import('layouts/BasicLayout'));
const Mine = loadable(() => import('pages/mine/Mine'));
const MineLecturesDetail = loadable(() => import('pages/mine/MineLecturesDetail'));
const LecturesPayment = loadable(() => import('pages/mine/MineLecturesPayment'));
const Error404 = loadable(() => import('layouts/Error404'));

export default {
  path: '/mine',
  element: <BasicLayout />,
  children: [
    { index: true, element: <Mine /> },
    { path: 'lectures/detail/:id', element: <MineLecturesDetail /> },
    { path: 'lectures/detail/:id/payment', element: <LecturesPayment /> },
  ],
  errorElement: <Error404 />,
};
