import AuthLayout from '../layouts/AuthLayout';

import loadable from '@loadable/component';

const SignIn = loadable(() => import('../pages/auth/SignIn'));
const SocialCallback = loadable(() => import('pages/auth/components/social/SocialCallback'));
const SignUp = loadable(() => import('pages/auth/SignUp'));
const Error404 = loadable(() => import('layouts/Error404'));

export default {
  path: '/auth',
  element: <AuthLayout />,
  children: [
    {
      path: 'sign-in',
      element: <SignIn />,
    },
    {
      path: 'sign-in/callback',
      element: <SocialCallback />,
    },
    {
      path: 'sign-up',
      element: <SignUp />,
    },
  ],
  errorElement: <Error404 />,
};
