import React, { ChangeEventHandler, FocusEventHandler, forwardRef } from 'react';
import styled from 'styled-components';
import colors from 'styles/theme/colors';

export interface TextInputProps {
  name?: string;
  placeHolder?: string;
  value?: string;
  max?: number;
  maxLength?: number;
  min?: number;
  minLength?: number;
  readOnly?: boolean;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  className?: string;
  type?: 'number';
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  boolean?: any;
}

const TextInput = forwardRef<HTMLInputElement, TextInputProps>((props, ref) => {
  const { name, placeHolder, value = '', leftIcon, rightIcon, readOnly, className, onChange, onBlur, ...rest } = props;

  return (
    <Wrapper className={className}>
      {leftIcon && leftIcon}
      <Input ref={ref} id={name} placeholder={placeHolder} onChange={onChange} onBlur={onBlur} value={value} readOnly={readOnly} />
      {rightIcon && rightIcon}
    </Wrapper>
  );
});

export default TextInput;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 48px;
  border-radius: 4px;
  border: 1px solid ${colors.gray.gray05};
  background: ${colors.system.white};
  cursor: text;

  & > img {
    cursor: auto;
  }
`;

const Input = styled.input`
  width: 100%;
  height: 100%;
  padding: 14px;
  border: 0;
  outline: none;
  background-color: transparent;

  &:disabled {
    background-color: ${colors.gray.gray06};
    color: ${colors.gray.gray04};
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
`;
