import loadable from '@loadable/component';

const BasicLayout = loadable(() => import('layouts/BasicLayout'));
const Home = loadable(() => import('pages/home/Home'));
const Search = loadable(() => import('pages/home/Search'));
const Inrtoduction = loadable(() => import('pages/home/Inrtoduction'));
const Error404 = loadable(() => import('layouts/Error404'));

export default {
  path: '/',
  element: <BasicLayout />,
  children: [
    { index: true, element: <Home /> },
    { path: '/search', element: <Search /> },
    { path: '/introduction', element: <Inrtoduction /> },
  ],
  errorElement: <Error404 />,
};
