import loadable from '@loadable/component';

const AuthLayout = loadable(() => import('layouts/AuthLayout'));
const FindPassword = loadable(() => import('pages/auth/FindPassword'));
const ResetPassword = loadable(() => import('pages/auth/ResetPassword'));
const Error404 = loadable(() => import('layouts/Error404'));

export default {
  path: '/password',
  element: <AuthLayout />,
  children: [
    {
      path: 'find',
      element: <FindPassword />,
    },
    {
      path: 'reset',
      element: <ResetPassword />,
    },
  ],
  errorElement: <Error404 />,
};
