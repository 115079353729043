import { ErrorMessage } from '@hookform/error-message';
import React, { PropsWithChildren, ReactElement } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';
import styled from 'styled-components';
import colors from 'styles/theme/colors';

interface FormItemProps {
  name: string;
  rules?: UseControllerProps['rules'];
  label?: React.ReactNode;
  hidden?: boolean;
  disabled?: boolean;
  success?: boolean;
  successText?: string;
  errorText?: string;
}

export default function FormItem(props: PropsWithChildren<FormItemProps>) {
  const { name, rules, label, success, successText, errorText, hidden, disabled, children } = props;
  const {
    field,
    formState: { errors },
  } = useController({
    name,
    rules,
  });

  return (
    <Wrapper style={hidden ? { display: 'none' } : undefined} disabled={disabled}>
      {label && (
        <Label htmlFor={name}>
          {label}
          {rules?.required && <span>*</span>}
        </Label>
      )}

      {children && React.cloneElement(children as ReactElement, { ...field })}

      {success && <p className="success">{successText}</p>}
      {errorText && <p className="error">{errorText}</p>}
      {!errorText && <ErrorMessage errors={errors} name={name} render={({ message }) => message && <p className="error">{message}</p>} />}
    </Wrapper>
  );
}

const Label = styled.label`
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  color: ${colors.gray.gray02};
`;

const Wrapper = styled.fieldset`
  & > p {
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
  }
  & > .success {
    color: ${colors.system.green};
  }
  & > .error {
    color: ${colors.system.error};
  }
`;
