import { useRef } from 'react';
import { flushSync } from 'react-dom';
import { useNavigate } from 'react-router-dom';

interface UseViewTransitionProps {
  link?: string;
  viewTransitionName?: string;
  state?: string;
}

function useViewTransition<T extends HTMLElement>({ link, viewTransitionName, state }: UseViewTransitionProps) {
  const refImage = useRef<T>(null);
  const nanigate = useNavigate();

  const onClickViewTransition = () => {
    transitionLink(link!);
  };

  const onClickParamsLink = (params: string) => {
    transitionLink(`${link}?${params}`);
  };

  function transitionLink(link: string, stateProps?: string, ...rest: any) {
    if (!document.startViewTransition) {
      return nanigate(link, { state: stateProps || state, ...rest });
    }

    if (!viewTransitionName) {
      return document.startViewTransition(() => {
        flushSync(() => {
          nanigate(link, { state: stateProps || state, ...rest });
        });
      });
    }

    if (!refImage.current) return;
    const current = refImage.current;
    current.style.viewTransitionName = viewTransitionName;
    document.startViewTransition(() => {
      flushSync(() => {
        nanigate(link, { state: stateProps || state, ...rest });
        current.style.viewTransitionName = '';
      });
    });
  }

  return { refImage, onClickViewTransition, onClickParamsLink, transitionLink };
}

export default useViewTransition;
