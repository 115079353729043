import styled from 'styled-components';
import { Outlet } from 'react-router';
import Header from './Header';
import { ScrollRestoration, Navigate } from 'react-router-dom';
import colors from 'styles/theme/colors';
import { Toaster } from 'react-hot-toast';
import { useRecoilValue } from 'recoil';
import { userState } from 'states/userState';

function AuthLayout() {
  const user = useRecoilValue(userState);

  if (user) return <Navigate to={'/'} />;

  return (
    <Wrapper>
      <Toaster position="bottom-center" />

      <Header />
      <Main>
        <Section>
          <ScrollRestoration />
          <Outlet />
        </Section>
      </Main>
      <CopyLight>copyright</CopyLight>
    </Wrapper>
  );
}

export default AuthLayout;

const Wrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const Main = styled.main`
  flex: 1;
  min-height: calc(100vh - 441px);
  overflow-y: auto;
  padding: 0;
`;

const Section = styled.section`
  width: 100%;
  max-width: 512px;
  margin: 0 auto;
  padding: 104px 16px 0;
`;

const CopyLight = styled.p`
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
  margin-bottom: 16px;
  color: ${colors.gray.gray03};
`;
