import loadable from '@loadable/component';

const BasicLayout = loadable(() => import('layouts/BasicLayout'));
const Schedule = loadable(() => import('pages/users/Schedule'));
const Users = loadable(() => import('pages/users/Users'));
const Error404 = loadable(() => import('layouts/Error404'));

export default {
  path: '/users',
  element: <BasicLayout />,
  children: [
    { index: true, element: <Users /> },
    { path: 'schedule/:id', element: <Schedule /> },
  ],
  errorElement: <Error404 />,
};
